import { Route, Routes } from "react-router-dom";
import { useRecoilValue } from "recoil";
import ButtonAppBar from "./components/app-bar";
import { adminUserAtom } from "./global-state/user.atom";
import { LoginAuthPage } from "./pages/login-auth.page";
import { UserDetailsPage } from "./pages/user-details.page";
import { UsersTablePage } from "./pages/users-table.page";

function App() {
    const isLoggedIn = useRecoilValue(adminUserAtom) !== null;

    return (
        <div className="App">
            <ButtonAppBar />
            {isLoggedIn ? (
                <Routes>
                    <Route path="/" element={<UsersTablePage />} />
                    <Route path="/:userId" element={<UserDetailsPage />} />
                </Routes>
            ) : (
                <Routes>
                    <Route path="/login-auth" element={<LoginAuthPage />} />
                </Routes>
            )}
        </div>
    );
}

export default App;
