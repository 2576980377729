import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { adminUserAtom } from "../global-state/user.atom";
import { UserLogout } from "./user-logout";
import { GoogleLoginButton } from "./google-login";

export default function ButtonAppBar() {
    const loggedInUser = useRecoilValue(adminUserAtom);
    const isInPopup = window.opener !== null;

    // do not render if in popup
    if (isInPopup) {
        return null;
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                    >
                        <Link
                            to="/"
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                            }}
                        >
                            Kamoa Support
                        </Link>
                    </Typography>
                    {loggedInUser ? (
                        <UserLogout user={loggedInUser} />
                    ) : (
                        <GoogleLoginButton />
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
